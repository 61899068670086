import { logException } from '@configs/azureAppInsights/utils';
import { Namespace } from '@configs/i18n';
import { colors } from '@fortum/elemental-ui';
import {
  addHeader,
  addMultiDeliveryPoints,
  customerInfo,
  pages,
} from '@utils/pdfUtils';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';

const A4_FORMAT_WIDTH_MM = 297;
const INK_GREY_RGB = 51;

interface DeliveryPointInfo {
  id: string;
  deliveryPointName: string;
  deliveryPointAddress: string;
  deliveryPointNumber: string;
  customerNumber: string;
}

export interface MultiDeliveryPoints {
  id: string;
  companyName: string;
  children: DeliveryPointInfo[];
}

export const useForcecastPdfDownloader = () => {
  const { t } = useTranslation<Namespace>('glossary');

  const downloadAsPDF = async (
    elementToDownload: HTMLElement,
    filename: string,
    title: string,
    imageDimensions: {
      width: number;
      height: number;
    },
    userName: string,
    deliveryPointsData: MultiDeliveryPoints[],
  ) => {
    try {
      // Lazy load the fonts
      await import('@assets/fonts/FortumSansTT-Regular-normal');
      await import('../../assets/fonts/FortumSansTT-Bold-bold');

      const dataUrl = await toPng(elementToDownload, {
        cacheBust: true,
        style: { backgroundColor: colors.snowWhite },
        width: 1140,
        height: 470,
        quality: 0.95,
      });

      const imageScale = imageDimensions.width / imageDimensions.height;
      const imageWidth = A4_FORMAT_WIDTH_MM - 45;
      const imageHeight = imageWidth / imageScale;

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF({
        orientation: 'landscape',
      });

      pdf.setFont('FortumSansTT-Regular', 'normal');
      pdf.setFontSize(12);
      pdf.setTextColor(INK_GREY_RGB);

      addHeader(pdf);

      customerInfo(pdf, t, userName, deliveryPointsData);

      pdf.setFont('FortumSansTT-Bold', 'bold');
      pdf.text(title, 21, 80);
      pdf.addImage(dataUrl, 'PNG', 21, 84, imageWidth, imageHeight);

      // Conditionally add multi-delivery points data
      const totalDeliveryPoints = deliveryPointsData.reduce(
        (acc, company) => acc + company.children.length,
        0,
      );

      if (totalDeliveryPoints > 1) {
        addMultiDeliveryPoints(pdf, deliveryPointsData, addHeader, t);
      }
      // Display the page numbers
      pages(pdf);

      // Download the PDF
      const pdfBlob = pdf.output('blob');
      const url = URL.createObjectURL(pdfBlob);
      // Open the PDF in new Tab instead of download
      window.open(url, '_blank');
    } catch (err) {
      logException(`Error while downloading as PDF. ${err}`);
    }
  };

  return { downloadAsPDF };
};
