import { useRef, useState, useLayoutEffect } from 'react';

export const useDivElementDimensions = (defaultDimensions: {
  width: number;
  height: number;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>(defaultDimensions);

  useLayoutEffect(() => {
    const resizeListener = () => {
      setDimensions(prev => ({
        ...prev,
        width: elementRef.current?.offsetWidth ?? prev.width,
      }));
    };

    resizeListener();

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return {
    dimensions,
    elementRef,
  };
};
