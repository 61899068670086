import { TabItem } from '@fortum/elemental-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getPath, getSubRouteName } from '@routers/utils';
import { subRoutes, routes } from '@routers/routes';
import i18n, { Namespace } from '@configs/i18n';
import { compact } from 'lodash';

export const useTabsWithRouter = () => {
  const { t } = useTranslation<Namespace>('glossary');
  const navigate = useNavigate();
  const location = useLocation();

  const tabItems: TabItem<string>[] = useMemo(() => {
    const allItems = [
      {
        id: subRoutes.consumption,
        name: t('page.name.consumption'),
      },
      {
        id: subRoutes.deliveryPoints,
        name: t('page.name.deliveryPoints'),
      },
      {
        id: subRoutes.reports,
        name: t('page.name.reports'),
      },
      {
        id: subRoutes.invoices,
        name: t('page.name.invoices'),
      },
      {
        id: subRoutes.ownInformation,
        name: t('page.name.ownInformation'),
      },
      {
        id: subRoutes.contactUs,
        name: t('page.name.contactUs'),
      },
    ];

    return compact(allItems);
  }, [i18n.language]);

  useEffect(() => {
    if (location.pathname === routes.index) {
      navigate(subRoutes.consumption);
    }
  }, [location.pathname]);

  const onTabChange = (tabId: string | number) =>
    navigate(getPath(tabId.toString()));

  const activeTab = useMemo(
    () => getSubRouteName(location.pathname),
    [location.pathname],
  );

  return {
    tabItems,
    onTabChange,
    activeTab,
  };
};
