export const queryKeys = {
  reports: 'reports',
  invoices: 'invoices',
  consumption: 'consumption',
  forecastedCost: 'forecastedCost',
  forecastedUsage: 'forecastedUsage',
  totalConsumption: 'totalConsumption',
  averageConsumption: 'averageConsumption',
  consumptionTrendKPI: 'consumptionTrendKPI',
  totalConsumptionKPI: 'totalConsumptionKPI',
  openInvoicesKPI: 'openInvoicesKPI',
  userInfo: 'userInfo',
  deliveryPoints: 'deliveryPoints',
  deliveryPoint: 'deliveryPoint',
  marketingInfo: 'marketingInfo',
  enclosures: 'enclosures',
  survey: 'survey',
};
