export const subRoutesValues = [
  'consumption',
  'invoices',
  'reports',
  'own-information',
  'delivery-points',
  'contact-us',
] as const;
export type SubRouteValue = typeof subRoutesValues[number];

export const subRoutes: { [key in string]: SubRouteValue } = {
  consumption: 'consumption',
  invoices: 'invoices',
  reports: 'reports',
  ownInformation: 'own-information',
  deliveryPoints: 'delivery-points',
  contactUs: 'contact-us',
};

export const routes = {
  index: '/',
  authorization: '/oauth/callback',
};
