import { Address } from '../address';

export interface RawUserInfo {
  firstName: string;
  lastName: string;
  street: string;
  postalCode: string;
  city: string;
  email: string;
  phoneNumber: string;
  customerType: string;
  customerNumber: string;
  emailCustomerCommunication: boolean;
  emailContractCommunication: boolean;
  emailNewsletter: boolean;
  emailEvents: boolean;
  emailSurveys: boolean;
  isRepresentative: boolean;
}

export type ConsentType =
  | 'EMAIL.CONTRACT'
  | 'EMAIL.NEWSLETTER'
  | 'EMAIL.EVENTS'
  | 'EMAIL.SURVEYS'
  | 'EMAIL.MARKETING';

export type MarketingPreferences = { [key in ConsentType]: boolean };

export type CustomerType = 'B2B' | 'B2C';
export interface UserPersonalInfo {
  firstName: string;
  lastName: string;
  address: Address;
  email: string;
  phoneNumber: string;
  customerType: CustomerType | null;
  customerNumber: string;
  isRepresentative: boolean;
}

export interface UserInfo extends UserPersonalInfo {
  marketingPreferences: MarketingPreferences;
}

export interface MarketingInfo {
  title: string;
  content: string;
  image: string;
}

export const isCustomerType = (type: string): type is CustomerType =>
  type === 'B2B' || type === 'B2C';

export const isUserInfo = (userInfo: unknown): userInfo is UserInfo => {
  const typedUserInfo = userInfo as UserInfo;
  return (
    typedUserInfo.firstName !== undefined &&
    typedUserInfo.lastName !== undefined &&
    typedUserInfo.email !== undefined
  );
};
