import { FULL_DATE_FORMAT } from '@commons/consts';
import { Namespace } from '@configs/i18n';
import jsPDF from 'jspdf';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import pdfLogo from '../assets/logo_Pdf.png';
import { MultiDeliveryPoints } from './hooks/useForcecastPdfDownloader';

// Function to add data to the pdfument
export const addMultiDeliveryPoints = (
  pdf: jsPDF,
  data: MultiDeliveryPoints[],
  fn: (pd: jsPDF) => void,
  t: TFunction<Namespace, undefined>,
) => {
  const addHeaderOnPage = () => {
    pdf.addPage();
    fn(pdf); // Call the function that draws the header content (like the image/logo)
    pdf.setFont('FortumSansTT-Regular', 'normal');
    pdf.setFontSize(12);
  };

  // Initial page setup with the header
  addHeaderOnPage();

  // Set initial y position
  let yPos = 55;

  // Utility function to split text into lines if too long
  const splitText = (text: string, maxLength: number): string[] => {
    if (text.length <= maxLength) {
      return [text];
    }
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';
    words.forEach(word => {
      if ((currentLine + word).length <= maxLength) {
        currentLine += `${word} `;
      } else {
        lines.push(currentLine.trim());
        currentLine = `${word} `;
      }
    });
    lines.push(currentLine.trim());
    return lines;
  };

  // Add table headers
  pdf.setFontSize(12);
  pdf.setFont('FortumSansTT-Bold', 'bold');
  pdf.text(t('reportTabs.pdf.infoCompanies'), 21, yPos - 13);

  const headers = [
    { text: t('reportTabs.pdf.companyName'), x: 30 },
    { text: t('reportTabs.pdf.deliveryPointName'), x: 98 },
    { text: t('reportTabs.pdf.deliveryPointAddress'), x: 158 },
    { text: t('reportTabs.pdf.deliveryPointID'), x: 218 },
    // { text: t('reportTabs.pdf.customerNumber'), x: 235 },
  ];

  headers.forEach(header => {
    const lines = splitText(header.text, 20); // Adjust maxLength as necessary
    lines.forEach((line, index) => {
      pdf.text(line, header.x, yPos + index * 5);
    });
  });

  // Increase y position based on the number of lines in the longest header
  const maxLines = Math.max(
    ...headers.map(header => splitText(header.text, 20).length),
  );
  yPos += maxLines * 5 + 7;

  // Iterate over the data and add company names and addresses
  data.forEach((company, companyIndex) => {
    // Avoid adding an extra page if the last company renders on the last page
    if (companyIndex > 0 && yPos > 180) {
      addHeaderOnPage();
      yPos = 55;
    }

    yPos += 5;
    fn(pdf);
    pdf.setFontSize(12);
    pdf.setFont('FortumSansTT-Regular', 'normal');
    // Split company name if it's too long
    const companyNameLines = splitText(company.companyName, 35);

    // Render each line of the company name
    companyNameLines.forEach((line, index) => {
      pdf.text(line, 30, yPos + index * 5);
    });

    // Increase yPos based on the number of lines in the company name
    yPos += companyNameLines.length * 5 + 5;

    // Add addresses
    company.children.forEach(child => {
      const {
        deliveryPointName,
        deliveryPointAddress,
        deliveryPointNumber,
        //    customerNumber,
      } = child;
      pdf.text(deliveryPointName, 100, yPos);
      pdf.text(deliveryPointAddress, 160, yPos);
      pdf.text(deliveryPointNumber, 220, yPos);
      // pdf.text(customerNumber, 235, yPos);
      yPos += 7;

      // Check if new page needed before moving to the next item
      if (yPos > 180) {
        addHeaderOnPage(); // Add a new page with header
        yPos = 40; // Reset yPos for the new page
      }
    });
  });

  // If the last page is blank, remove it
  const totalPages = (pdf as any).internal.getNumberOfPages();
  pdf.setPage(totalPages);
  if (yPos === 55) {
    pdf.deletePage(totalPages); // Remove the last blank page
  }
};

export const pages = (pdf: jsPDF) => {
  pdf.setFontSize(12);
  pdf.setFont('FortumSansTT-Regular', 'normal');
  const totalPages = (pdf as any).internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i += 1) {
    pdf.setPage(i);
    pdf.text(`${i}/${totalPages}`, 270, pdf.internal.pageSize.getHeight() - 10);
  }
};

// Utility function to fetch and convert image to base64
const fetchBase64Image = (url: string): Promise<string> => {
  return fetch(url)
    .then(response => response.blob())
    .then(blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

let cachedLogoBase64: string | null = null;

// Preload the logo and cache it as base64
const preloadLogo = async () => {
  if (cachedLogoBase64) return; // If already cached, do nothing
  cachedLogoBase64 = await fetchBase64Image(pdfLogo); // Load and convert the image to base64
};

// Call this function early in the application lifecycle
preloadLogo();

export const addHeader = (pdf: jsPDF) => {
  // Ensure the logo is cached
  if (cachedLogoBase64) {
    const logoWidth = 0;
    const logoHeight = 20;

    // Use the cached base64 image directly
    pdf.addImage(cachedLogoBase64, 'PNG', 12, 12, logoWidth, logoHeight);
  }
};

export const customerInfo = (
  pdf: jsPDF,
  t: TFunction<Namespace, undefined>,
  user: string,
  data: MultiDeliveryPoints[],
) => {
  pdf.setFontSize(12);
  pdf.setFont('FortumSansTT-Regular', 'normal');

  pdf.text(`${t('reportTabs.pdf.creationDate')}`, 210, 50);
  pdf.text(`${t('reportTabs.pdf.deliveryPointName')}`, 21, 56);
  pdf.text(`${t('reportTabs.pdf.deliveryPointAddress')}`, 21, 62);
  pdf.text(`${t('reportTabs.pdf.deliveryPointID')}`, 21, 68);

  pdf.text(moment().format(FULL_DATE_FORMAT), 243, 50);

  if (data.length === 1) {
    // Only one company
    pdf.text(data[0].companyName, 21, 45);
    // pdf.text(data[0].children[0].customerNumber, 21, 56);
    if (data[0].children.length === 1) {
      // Only one child
      pdf.text(data[0].children[0].deliveryPointName, 78, 56);
      pdf.text(data[0].children[0].deliveryPointAddress, 78, 62);
      pdf.text(data[0].children[0].deliveryPointNumber, 78, 68);
    } else {
      // Multiple children
      pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 56);
      pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 62);
      pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 68);
    }
  } else {
    // Multiple companies
    pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 56);
    pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 62);
    pdf.text(`${t('reportTabs.pdf.multipleSelected')}`, 78, 68);
  }
};
